.documentation {
  display: grid;
  grid-template-areas:
    "header header"
    "aside main"
    "footer footer";
  height: 100dvh;
  overflow: hidden;

  main {
    align-content: start;
    align-items: start;
    box-sizing: border-box;
    display: grid;
    gap: var(--gap-medium);
    justify-content: center;
    overflow-y: scroll;
    padding: var(--spacing-8) var(--spacing-10);
    width: calc(100vw - var(--doc-sidebar-width));

    button {
      display: none;
      width: fit-content;
    }
  }

  .main-content {
    align-content: start;
    column-gap: var(--gap-medium);
    container: main-content / layout inline-size;
    display: grid;
    grid-template-columns: minmax(auto, var(--doc-content-width)) minmax(auto, 30ch);
    grid-auto-rows: auto;
    position: relative;

    & > :where(*) {
      grid-column: 1 / 1;
    }

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.5rem;
    }

    h3 {
      font-size: 1.25rem;
    }

    h1, h2, h3, h4 {
      font-weight: var(--font-weight-normal);
      margin-block-end: 0;
      scroll-margin-top: var(--spacing-8);
    }

    #markdown-toc {
      grid-column: 2 / -1;
      grid-row: 1 / 1000;
    }

    p:has(img) {
      text-align: center;
    }

    img {
      border-radius: var(--border-radius-xlarge);
      margin-inline: auto;
      max-width: 100%;
      max-height: 600px;
    }
  }

  @media(max-width: 800px) {
    main {
      grid-column: 1 / -1;
      width: 100%;

      button {
        display: inline-flex;
      }
    }
  }

  @container main (width < 800px) {
    .main-content {
      grid-template-columns: 1fr;
    }

    #markdown-toc {
      display: none;
    }
  }
}
