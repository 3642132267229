:root {
  --spacing-unit: 4px;

  --spacing-px: calc(var(--spacing-unit) * 0.25);
  --spacing-05: calc(var(--spacing-unit) * 0.5); /* 2px */
  --spacing-1: var(--spacing-unit); /* 4px */
  --spacing-2: calc(var(--spacing-unit) * 2); /* 8px */
  --spacing-3: calc(var(--spacing-unit) * 3); /* 12px */
  --spacing-4: calc(var(--spacing-unit) * 4); /* 16px */
  --spacing-5: calc(var(--spacing-unit) * 5); /* 20px */
  --spacing-6: calc(var(--spacing-unit) * 6); /* 24px */
  --spacing-7: calc(var(--spacing-unit) * 7); /* 28px */
  --spacing-8: calc(var(--spacing-unit) * 8); /* 32px */
  --spacing-9: calc(var(--spacing-unit) * 9); /* 36px */
  --spacing-10: calc(var(--spacing-unit) * 10); /* 40px */
  --spacing-11: calc(var(--spacing-unit) * 11); /* 44px */
  --spacing-12: calc(var(--spacing-unit) * 12); /* 48px */
  --spacing-14: calc(var(--spacing-unit) * 14); /* 56px */
  --spacing-15: calc(var(--spacing-unit) * 15); /* 60px */
  --spacing-16: calc(var(--spacing-unit) * 16); /* 64px */
  --spacing-18: calc(var(--spacing-unit) * 18); /* 72px */
  --spacing-20: calc(var(--spacing-unit) * 20); /* 80px */
  --spacing-24: calc(var(--spacing-unit) * 24); /* 96px */
  --spacing-28: calc(var(--spacing-unit) * 28); /* 112px */
  --spacing-30: calc(var(--spacing-unit) * 30); /* 120px */
  --spacing-32: calc(var(--spacing-unit) * 32); /* 128px */
  --spacing-34: calc(var(--spacing-unit) * 34); /* 136px */
  --spacing-40: calc(var(--spacing-unit) * 40); /* 160px */

  --spacing-1-minus: calc(var(--spacing-unit) * -1); /* -4px */
  --spacing-2-minus: calc(var(--spacing-unit) * -2); /* -8px */
  --spacing-3-minus: calc(var(--spacing-unit) * -3); /* -12px */
  --spacing-4-minus: calc(var(--spacing-unit) * -4); /* -16px */
  --spacing-5-minus: calc(var(--spacing-unit) * -5); /* -20px */
  --spacing-6-minus: calc(var(--spacing-unit) * -6); /* -24px */
  --spacing-8-minus: calc(var(--spacing-unit) * -8); /* -32px */
  --spacing-10-minus: calc(var(--spacing-unit) * -10); /* -40px */
  --spacing-11-minus: calc(var(--spacing-unit) * -11); /* -44px */
  --spacing-12-minus: calc(var(--spacing-unit) * -12); /* -48px */
  --spacing-13-minus: calc(var(--spacing-unit) * -13); /* -52px */
  --spacing-14-minus: calc(var(--spacing-unit) * -14); /* -56px */
  --spacing-16-minus: calc(var(--spacing-unit) * -16); /* -64px */
  --spacing-20-minus: calc(var(--spacing-unit) * -20); /* -80px */
  --spacing-24-minus: calc(var(--spacing-unit) * -24); /* -96px */
  --spacing-30-minus: calc(var(--spacing-unit) * -30); /* -120px */
}
