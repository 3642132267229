html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

/* usage of :where makes specificity lower */
:where(h1, h2, h3, h4, h5, h6) {
  color: var(--title-color);
  font-weight: var(--font-weight-normal);
}

:where(h1) {
  font-size: 2.5rem;
}

:where(h2) {
  font-size: 1.875rem;
}

:where(h3) {
  font-size: 1.5rem;
  line-height: 1.875rem;
}

:where(h4) {
  font-size: 1.125rem;
  line-height: 1.6875rem;
}

:where(h4, h5, h6) {
  font-weight: var(--font-weight-semibold);
}

:where(a, a:focus, a:visited) {
  color: var(--link-color);
  transition: all var(--base-duration) var(--base-timing);
}

:where(strong) {
  font-weight: var(--font-weight-semibold);
}

h2, h3, h4 {
  &[id] {
    position: relative;

    .heading-anchor {
      bottom: 0.2em;
      font-size: 0.75em;
      font-weight: 500;
      margin-left: var(--gap-xsmall);
      opacity: 0;
      position: absolute;
      transition: opacity 0.3s;
    }

    &:hover .heading-anchor {
      opacity: 1;
    }
  }
}
