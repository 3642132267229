:where(input) {
  appearance: none;
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  font-family: var(--font-text);
  font-size: 0.875rem;
  height: var(--spacing-10);
  padding: var(--spacing-2) var(--spacing-3);

  &[type="checkbox"] {
    aspect-ratio: 1 / 1;
    margin: 0;
    margin-right: var(--spacing-2);
    padding: 0;
    height: var(--spacing-5);
  }
}

:where(input):hover {
  border-color: var(--text-color);
}

:where(input:focus, input:active) {
  border-color: var(--border-color-focus);
  outline: none;
}
