body {
  font-family: var(--font-text);
  grid-template-rows: auto 1fr auto;
  line-height: 1.5;
  margin: 0;
  min-height: 100vh;
  position: relative;
}

body > header {
  grid-area: header;
}

body > aside {
  grid-area: aside;
}

body > main {
  container: main / inline-size;
  grid-area: main;
}

body > footer {
  grid-area: footer;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr min(var(--page-content-width), calc(100% - var(--spacing-20))) 1fr;
  column-gap: var(--spacing-10);
  justify-content: center;
}

.wrapper > * {
  grid-column: 2;
}

.wrapper[data-wrapper-size="small"] {
  --page-content-width: 75ch;
}

.full-bleed {
  grid-column: 1 / -1;
}

.double-pane {
  --side-min-width: 100px;

  display: grid;
  grid-template-columns: max(var(--side-min-width), min(25%, 200px)) auto;
  gap: var(--spacing-10) clamp(var(--spacing-10), 15%, var(--spacing-34));

  aside {
    border-right: var(--border-default);
    box-sizing: border-box;
    height: 100%;
    padding-block: var(--spacing-14);
    padding-right: var(--spacing-10);
    width: 100%
  }
}

@container main (width < 800px) {
  .double-pane {
    gap: var(--gap-large);
    grid-template-columns: 1fr;
    padding-block: var(--spacing-10);

    aside {
      border-right: 0;
    }

    .wrapper {
      gap: 0;
      grid-template-columns: 1fr;
    }
  }
}
