guide-list {
  container: guide-list / inline-size;
  display: flex;
  flex-direction: column;
  gap: var(--gap-medium);
  width: 100%;

  .list-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gap-medium);
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;

    .card {
      background-color: var(--bg-color);
      box-sizing: border-box;
      min-height: 270px;

      p {
        flex-grow: 1;
      }

      time {
        color: var(--gray-50);
        font-size: 0.875rem;
      }
    }
  }

  @container guide-list (width < 800px) {
    .list-container {
      border-top: var(--border-default);
      grid-template-columns: 1fr;
    }

    .card {
      min-height: revert;
    }
  }
}
