side-item {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2) var(--gap-small);

  .item {
    align-items: center;
    border-radius: var(--border-radius-medium);
    display: inline-flex;
    gap: var(--gap-small);
    padding: var(--spacing-1) var(--spacing-3);

    &[data-current-parent="true"] {
      color: var(--link-color);
    }

    &[aria-current="true"] {
      background-color: var(--bg-color-selected);
      color: var(--link-color);
    }

    > :where(a, a:focus, a:visited, div) {
      color: currentColor;
      flex-grow: 1;
      text-decoration: none;
    }

    .link:hover {
      color: var(--base-color);
    }

    > svg {
      flex-shrink: 0;
      width: 1em;
    }
  
    li {
      display: inline-flex;
    }
  }

  button {
    font-size: .75rem;
    height: var(--spacing-6);
    padding: 0 var(--spacing-1);
    width: fit-content;

    svg {
      height: 0.5em;
    }
  }

  &[aria-expanded="true"] {
    > .item > button svg {
      rotate: 90deg;
    }

    > .sidebar-sublist {
      display: block
    }
  }

  .sidebar-sublist [data-current-parent="true"] {
    background-color: revert;
  }
}
