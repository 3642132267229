footer-column {
  &,
  .items {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
  }

  .items {
    gap: var(--spacing-2);
  }

  .title {
    font-size: 1rem;
    font-weight: var(--font-weight-semibold);
  }
}
