:root {
  --font-text: "Avenir Next";

  --text-color: var(--gray-70);
  --text-color-disabled: var(--gray-40);
  --text-color-secondary: var(--slate-70);
  --text-color-inverted: var(--white);
  --title-color: var(--black);

  --link-color: var(--blue-60);
  --link-color-hover: var(--gray-50);

  --page-content-width: 1300px;

  --gap-default: var(--spacing-6);

  --font-weight-normal: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  --text-xxs: 0.625rem;
  --text-xs: 0.75rem;
  --text-sm: 0.875rem;
  --text-base: 1rem;
  --text-l: 1.125rem;
  --text-xl: 1.25rem;
  --text-2xl: 1.5rem;
  --text-3xl: 2rem;
  --text-5xl: 3rem;
}
