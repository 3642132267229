guide-category {
  align-items: center;
  border-radius: var(--border-radius-xlarge);
  color: var(--text-color);
  container: guide-category / inline-size;
  display: flex;
  flex-direction: column; 
  gap: var(--gap-medium);
  width: 100%;

  .list-header {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xsmall);
    width: 100%;

    a {
      align-items: center;
      display: inline-flex;
      font-weight: var(--font-weight-semibold);
      gap: var(--spacing-2);
      text-decoration: none;

      &:hover {
        gap: var(--spacing-3);
        transition: all 0.2s ease;
      }
    }
  }

  h2, p {
    margin: 0;
  }
}
