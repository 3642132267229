footer {
  border-top: var(--border-default);

  .columns {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-6);
    justify-content: space-between;
    padding-block: var(--spacing-12);
  }

  :is(a.link) {
    color: var(--text-color);
    text-decoration: none;
  }

  :is(a.link:hover) {
    color: var(--text-color);
    text-decoration: underline;
    text-underline-offset: calc(var(--spacing-1) + var(--spacing-05));
  }

  .bottom {
    border-top: var(--border-default);
    display: flex;
    flex-wrap: wrap;
    font-size: 0.75rem;
    gap: var(--spacing-8);
    justify-content: space-between;
    padding-block: var(--spacing-10);
  }

  .bottom-column {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-3) var(--spacing-6);
  }
}
