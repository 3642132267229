guide-sidebar {
  container: guide-sidebar / inline-size;

  :is(aside) {
    display: flex;
    flex-direction: column;
    gap: var(--gap-medium);

    hr {
      border: 0;
      border-bottom: var(--border-default);
      inline-size: 100%;
      margin: 0;
    }
  }

  bump-cta {
    position: sticky;
    top: var(--spacing-28);
  }
}
