.guides {
  .section-container {
    container: guides / inline-size;
    display: flex;
    flex-direction: column;
    gap: var(--gap-xlarge);
    margin-block: var(--spacing-16);
  }

  guide-list {
    .list-container {
      grid-template-columns: repeat(3, 1fr);

      @container guides (width < 800px) {
        grid-template-columns: 1fr;
      }
    }

    .browse-all {
      flex-direction: row;
      gap: var(--gap-small);
      justify-content: center;
      padding-block: var(--spacing-4);
    }
  }
}

.guide {
  .double-pane {
    --side-min-width: 250px;
  }

  .guide-content {
    padding-block: var(--spacing-10);

    h1 {
      font-size: clamp(2rem, 10vw, 2.5rem);
    }

    .guide-cover {
      margin-bottom: var(--spacing-10);
    }

    img {
      border-radius: var(--border-radius-xlarge);
      width: 100%;
    }
  }

  @container main (width < 800px) {
    aside {
      border: 0;
      padding: 0;
    }

    .guide-content {
      border-top: var(--border-default);
    }
  }
}
