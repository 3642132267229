:where(blockquote) {
  --callout-bg-color: var(--bg-color-secondary);
  --callout-border-color: var(--border-color-default);
  --callout-icon-path: url(/images/icons/info.svg);
  border-left: 3px solid var(--border-color-default);
  margin-inline: 0;
  padding: var(--spacing-1) var(--spacing-3);

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:where(.info, .success, .warning, .error) {
    --callout-bg-color: var(--bg-color-secondary);
    --callout-border-color: var(--border-color-default);
    --callout-icon-path: url(/images/icons/info.svg);

    background-color: var(--callout-bg-color);
    border-left: 4px solid var(--callout-border-color);
    border-radius: var(--border-radius-medium);
    display: flex;
    flex-direction: column;
    padding: var(--spacing-4);
    padding-left:  var(--spacing-12);
    position: relative;

    &::before {
      background: var(--callout-icon-path);
      content: "";
      display: inline-block;
      height: var(--spacing-4);
      left: var(--spacing-4);
      min-width: var(--spacing-4);
      position: absolute;
      top: calc(var(--spacing-4));
      width: var(--spacing-4);
    }

    &.info {
      --callout-bg-color: var(--bg-color-info);
      --callout-border-color: var(--text-color-info);
      --callout-icon-path: url(/images/icons/info.svg);
    }

    &.success {
      --callout-bg-color: var(--bg-color-success);
      --callout-border-color: var(--text-color-success);
      --callout-icon-path: url(/images/icons/success.svg);
    }

    &.warning {
      --callout-bg-color: var(--bg-color-warning);
      --callout-border-color: var(--text-color-warning);
      --callout-icon-path: url(/images/icons/warning.svg);
    }

    &.error {
      --callout-bg-color: var(--bg-color-error);
      --callout-border-color: var(--text-color-error);
      --callout-icon-path: url(/images/icons/error.svg);
    }
  }
}
