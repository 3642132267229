side-bar {
  border-right: var(--border-default);
  overflow-y: scroll;
  width: var(--doc-sidebar-width);
  z-index: 90;

  aside {
    padding: var(--spacing-14) var(--spacing-4);
  
    > button {
      display: none;
    }
  }

  :is(h1) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: var(--text-l);
    margin-block-end: var(--spacing-4);

    span {
      color: var(--text-color-secondary);
      font-size: var(--text-base);
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
  }

  .sidebar-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
  }

  bump-cta {
    margin-block-start: var(--spacing-8);
  }

  .sidebar-sublist {
    border-left: var(--border-default);
    display: none;
    list-style-type: none;
    margin: 0;
    margin-bottom: var(--spacing-2);
    margin-left: var(--spacing-5);

    .sidebar-sublist {
      margin-left: var(--spacing-7);
    }

    .item {
      border-left: 2px solid transparent;
      border-radius: 0 var(--border-radius-medium) var(--border-radius-medium) 0;
      margin-left: -1px;
      padding-left: var(--spacing-3);
    }
  }

  @media(max-width: 800px) {
    background-color: var(--bg-color);
    bottom: 0;
    box-shadow: var(--box-shadow-medium);
    left: calc(var(--doc-sidebar-width) * -1);
    max-height: 100vh;
    overflow-y: scroll;
    overscroll-behavior: contain;
    padding-inline-start: revert;
    position: fixed;
    transition: all 0.4s ease-in-out;
    top: 0;

    &[data-toggle-active="true"] {
      left: 0;
    }

    aside > button {
      align-items: center;
      background: transparent;
      display: flex;
      margin-bottom: var(--spacing-6);
      margin-top: var(--spacing-12);

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
