nav-item :is(a, a:visited, a:focus) {
  border-radius: var(--border-radius-medium);
  color: var(--text-color);
  padding: var(--spacing-2) var(--spacing-3);
  text-decoration: none;
}

nav-item :is(a:hover),
nav-item[aria-current=true] a {
  background-color: var(--bg-color-selected);
  color: var(--base-color);
}
