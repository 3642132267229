table {
  border-spacing: 0;
  overflow: hidden;

  > tbody:first-child {
    tr:first-child {
      td {
        border-top: var(--border-default);

        &:first-child {
          border-top-left-radius: var(--border-radius-large);
        }

        &:last-child {
          border-top-right-radius: var(--border-radius-large);
        }
      }
    }
  }

  &.word-break {
    td {
      word-break: break-all;
    }
  }
}

thead {
  th {
    background-color: var(--bg-color-secondary);
    border-block: var(--border-default);
    font-weight: 500;
    text-align: left;

    &:first-child {
      border-left: var(--border-default);
      border-top-left-radius: var(--border-radius-large);
    }

    &:last-child {
      border-right: var(--border-default);
      border-top-right-radius: var(--border-radius-large);
    }

    code {
      white-space: nowrap;
    }
  }
}

th, td {
  padding: var(--spacing-4);
}

td {
  border-bottom: var(--border-default);
}

tr {
  td:first-child {
    border-left: var(--border-default);
  }

  td:last-child {
    border-right: var(--border-default);
  }
  
  &:last-child {
    td:first-child {
      border-bottom-left-radius: var(--border-radius-large);
    }
  
    td:last-child {
      border-bottom-right-radius: var(--border-radius-large);
    }
  }
}
