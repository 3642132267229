author-card {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xsmall);

  .author-image {
    align-items: center;
    background-image: var(--bg-color-gradient);
    border-radius: var(--border-radius-xlarge);
    display: flex;
    flex-shrink: 0;
    height: 100px;
    justify-content: center;
    margin-bottom: var(--spacing-3);
    overflow: hidden;
    position: relative;
    width: 100px;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      z-index: 1;
    }

    &::before {
      aspect-ratio: 1 / 1;
      background-color: var(--blue-30);
      border-radius: 100%;
      content: "";
      display: block;
      height: 35%;
      position: absolute;
    }

    &::after {
      background-color: var(--blue-30);
      border-radius: 60% 60% 0 0;
      content: "";
      display: block;
      height: 50%;
      position: absolute;
      top: 70%;
      width: 60%;
    }
  }

  .author-description {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xsmall);
  }

  h3, p {
    margin: 0;
  }

  h3 {
    font-size: 1.125rem;
    font-weight: var(--font-weight-semibold);
    line-height: 1;
    margin-top: var(--spacing-2);
  }

  .links {
    align-items: center;
    display: flex;
    gap: var(--gap-small);

    svg {
      color: var(--text-color);
      width: 20px;
    }
  }

  @container guide-sidebar (width > 250px) {
    align-items: center;
    flex-direction: row;
    gap: var(--gap-medium);
  }
}
