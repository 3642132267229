.category {
  guide-list .list-container {
    padding-block: var(--spacing-20);
  }

  @container main (width < 800px) {
    aside {
      padding: 0;

      ul {
        display: flex;
        flex-direction: row;
      }
    }

    .category-items {
      padding: 0;
    }
  }
}
